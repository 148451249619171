import React from "react";

import highFiveImage from "@images/high-five-hands.png";
import calculatorImage from "@images/calculator.png";
import blurredWomanImage from "@images/blurred-woman.png";
import cogsImage from "@images/cogs.png";

const Slogan = () => {
  const grayscale = "100%";

  return (
    <section className="bg-gray-100">
      <div className="max-w-7xl mx-auto py-12 px-4 md:py-24 sm:px-6 lg:px-8">
        <ul className="pt-12 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4 sm:gap-x-6 xl:gap-x-8">
          <Li title="Acquire&nbsp;New Customers" image={highFiveImage} grayscale={grayscale} />
          <Li title="Increase Revenues" image={calculatorImage} grayscale={grayscale} />
          <Li title="Retain Customers" image={blurredWomanImage} grayscale={grayscale} />
          <Li title="Efficient Payments" image={cogsImage} grayscale={grayscale} />
        </ul>
      </div>
    </section>
  );
}

const Li = ({ title, image, grayscale }) => (
  <li className="relative">
    <div className="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-highlight group block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden">
      <img src={image} alt="" style={{ filter: `grayscale(${grayscale})` }} className="opacity-80 group-hover:opacity-70 object-cover pointer-events-none"/>
      <div aria-hidden="true" className="absolute inset-0 bg-highlight bg-opacity-30"></div>
      <button type="button" className="absolute nset-0">
        <span className="sr-only">{title}</span>
      </button>
    </div>
    <p className="mt-2 block max-w-min mx-auto capitalize text-center text-xl font-medium text-gray-500 pointer-events-none">{title}</p>
  </li>
);

export default Slogan;

